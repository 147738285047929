<template>
  <!--
    The view for the Terminal status
  -->
  <div class="installationDetail_TerminalStatus">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Portlet
        :title="$t('status')"
        icon="tablet-alt"
      >
        <div class="row">
          <div
            :class="['col-12 col-lg-6 col-md-6', { 'col-sm-4half col-sm-6half col-xl-4' : !singleSide }]"
          >
            <Portlet
              :title="$t('deviceDetailComponent.screenshot')"
              icon="image"
            >
              <template slot="buttons">
                <toggle-button
                  v-model="screenAutoRefresh"
                  :labels="{ checked: 'Autorefresh on', unchecked: 'Autorefresh off' }"
                  :color="{ checked: 'green', unchecked: 'gray' }"
                  :height="30"
                  :width="115"
                  :font-size="10"
                  class="m-0 mr-2"
                  :sync="true"
                />
                <a
                  v-if="uuid"
                  href="#"
                  m-portlet-tool="reload"
                  class="m-portlet__nav-link m-portlet__nav-link--icon reloadButton"
                  @click.prevent="$refs.deviceDetailScreenshot.loadScreenshot(); spinScreenshot(true)"
                >
                  <font-awesome-icon
                    :class="['alt-pointer color-primary', { 'fa-spin' : loadingScreenshot}]"
                    icon="sync-alt"
                  />
                </a>
              </template>
              <DeviceDetailScreenshot
                v-if="uuid"
                ref="deviceDetailScreenshot"
                :uuid="uuid"
                :auto-refresh="screenAutoRefresh"
                @stopReloadingScreenshot="spinScreenshot"
                @stopAutorefresh="changeAutoRefresh"
              />
            </Portlet>
          </div>
          <div
            v-if="!isMediaStreamer"
            :class="['col-12 col-lg-6 col-md-6', { 'col-sm-8half col-sm-6half col-xl-8' : !singleSide }]"
          >
            <div class="row">
              <DeviceZabbixDialogStatus
                v-if="uuid"
                :class="['mb-4 col-lg-12 col-12 ', { ' col-xl-6 col-sm-12half' : !singleSide }]"
                :uuid="uuid"
                :is-photoprint="isPhotoprintTerminal"
                :single-side="singleSide"
              />
              <SalesCountReport
                v-if="hasSalesCount"
                class="mb-4 col-12 col-xl-6 col-lg-12 col-sm-12half"
                :installation-id="installationId"
              />
            </div>
          </div>
        </div>
      </Portlet>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailTerminalStatus",
  components: {
    DeviceDetailScreenshot: () => import('@/components/Device/DeviceDetail/DeviceDetailScreenshot.vue'),
    DeviceZabbixDialogStatus: () => import('@/components/Device/DeviceZabbixDialogStatus.vue'),
    SalesCountReport: () => import('@/components/Terminal/SalesCountReport.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      uuid: null,
      loadingScreenshot: true,
      screenAutoRefresh: false,
      isPhotoprintTerminal: null,
      isMediaStreamer: false,
      hasSalesCount: false,
      singleSide: false
    };
  },
  created () {
    this.getDevices();
  },
  metaInfo () {
    return {
      title: this.$t('status')
    };
  },
  methods: {
    changeAutoRefresh (val) {
      this.screenAutoRefresh = val;
    },
    spinScreenshot (val) {
      this.loadingScreenshot = val;
    },
    getDevices () {
      this.loading = true;
      this.axios.get(`/CentralDeviceManagement/GetFromInstallationId?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          var devices = response.data;
          if(devices) {
            var mediaStreamer = devices.find(x => x.applicationType.includes("mediastreamer"));
            if(mediaStreamer) {
              this.isMediaStreamer = true;
              this.uuid = mediaStreamer.uuid;
            }
            else {
              var firstTerminal = devices.find(x => x.applicationType.includes("terminal-v") || x.applicationType.includes("photopoint_liveview") ||
              x.applicationType.includes("terminal-ADVT") || x.applicationType.includes("terminal-photoprint"));
              this.hasSalesCount = firstTerminal.applicationType.includes("terminal-photoprint") || firstTerminal.applicationType.includes("terminal-ADVT");
              this.isPhotoprintTerminal = firstTerminal.applicationType.includes("terminal-photoprint");
              this.singleSide = firstTerminal.applicationType.includes("terminal-v") || firstTerminal.applicationType.includes("photopoint_liveview"); 
              this.uuid = firstTerminal.uuid;
            }
          }
          else {
             this.$snotify.error("Devices not found.");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
@media (min-width: 1350px) {
    .col-sm-8half, .col-sm-4half {
        float: left;
    }

    .col-sm-4half {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3%;
      flex: 0 0 33.3%;
      max-width: 33.3%;
    }

    .col-sm-8half {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.7%;
      flex: 0 0 66.7%;
      max-width: 66.7%;
    }
}

@media (min-width: 1200px) and (max-width: 1350px) {
    .col-sm-6half {
        float: left;
    }
    .col-sm-6half {
        -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-sm-12half {
        -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
}
</style>